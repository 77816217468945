import { getApnTag } from "../../../../components/Ad/utils/ads/functions";
import { getConfig } from "../../../../config";

const { features, NEXT_PUBLIC_NEWSROOM } = getConfig();

export const setPageOpts = async () => {
  const apntag = await getApnTag();
  if (apntag && features.some(feature => feature === "ADS_ENABLED")) {
    apntag.anq.push(function () {
      apntag.setPageOpts({
        member: 9943,
        keywords: {
          "se-generic-page": "index",
          "aa-sch-country_code": "se",
          "aa-sch-publisher": NEXT_PUBLIC_NEWSROOM,
          "se-generic-appmode": "notinapp",
          "se-generic-pagegen": "smp",
          "se-generic-screenwidth": window.outerWidth,
          "se-generic-screenheight": window.outerHeight,
          "se-generic-viewportwidth": window.innerWidth,
          "se-generic-viewportheight": window.innerHeight
        }
      });
    });
  } else {
    console.error("apntag is not loaded");
  }
};
