import * as Sentry from "@sentry/browser";

import type { UserSlice } from "../types";

import { getSpidProfile } from "../../../../client";

const handleUserInfo = async (setUserInfo: UserSlice["setUserInfo"]) => {
  try {
    const settings = await getSpidProfile();

    setUserInfo({
      id: settings.user_id,
      name: settings.name,
      email: settings.email
    });

    return true;
  } catch (error) {
    console.error("Error when fetching spid profile: ", error);
    Sentry.captureException(error);
    return false;
  }
};

export { handleUserInfo };
