import type { Identity } from "@schibsted/account-sdk-browser";

const getIsLoggedIn = async (identity?: Identity) => {
  if (!identity) {
    return false;
  }

  try {
    return await identity.isLoggedIn();
  } catch (error) {
    console.error(`Error when isLoggedIn check: ${error}`);

    return false;
  }
};

export { getIsLoggedIn };
