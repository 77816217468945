import { useCallback, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { setUser, setContext, setTag } from "@sentry/nextjs";

import type { TCFConfig } from "@schibsted/sourcepoint";
import type { SiteData } from "../../../server/getData";

import { getConfig } from "../../../config";
import { useGlobalStore } from "../store";
import { useCookiePermission } from "../../../hooks/cookiePermission/useCookiePermission";
import { loadAdsWhenNavigate } from "../../../components/Ad/utils/ads/xandr";
import { setCollapsedId } from "./actions/setCollapsedAdIds";
import { setPageOpts } from "./utils/setPageOpts";
import { initPulse, initTCF, waitForConsent } from "../../../lib/privacy";
import { useConsentToAll } from "../../../hooks/cookiePermission/useConsentToAll";

const {
  NEXT_PUBLIC_PSI_APP_ID,
  NEXT_PUBLIC_PRIVACY_MANAGER_ID,
  NEXT_PUBLIC_SPID_CLIENT_ID,
  NEXT_PUBLIC_TCF_PROPERTY_ID,
  NEXT_PUBLIC_TCF_PROPERTY_HREF,
  translation,
  NEXT_PUBLIC_PULSE_CLIENT_ID,
  NEXT_PUBLIC_PULSE_DEPLOY_STAGE,
  NEXT_PUBLIC_PULSE_PRODUCT_TAG,
  NEXT_PUBLIC_NEWSROOM
} = getConfig();

const useSiteState = (serverSiteData: SiteData) => {
  const [siteStateReady, setSiteStateReady] = useState(false);
  const userState = useGlobalStore();
  const { setFullSiteState, setCollapsedAdId } = useGlobalStore();
  const router = useRouter();
  const marketingConsent = useCookiePermission("CMP:marketing");
  const advertisingConsent = useCookiePermission("CMP:advertising");
  const consentToAll = useConsentToAll();

  const onNavigate = useCallback(async () => {
    if (serverSiteData) {
      loadAdsWhenNavigate({
        user: userState,
        ads: serverSiteData.adRules,
        marketingConsent,
        advertisingConsent,
        consentToAll
      });
    }
  }, [
    userState,
    serverSiteData,
    marketingConsent,
    advertisingConsent,
    consentToAll
  ]);

  useEffect(() => {
    if (serverSiteData) {
      setFullSiteState({
        categories: serverSiteData.categories,
        allCategories: serverSiteData.allCategories,
        adRules: serverSiteData.adRules,
        collapsedAdIds: [],
        salesposter: serverSiteData.salesposter
      });

      setPageOpts();
      setCollapsedId(setCollapsedAdId);

      setSiteStateReady(true);
    }
  }, [setCollapsedAdId, setFullSiteState, serverSiteData]);

  useEffect(() => {
    const tcfConfig: TCFConfig = {
      clientId: NEXT_PUBLIC_SPID_CLIENT_ID,
      privacyManagerId: NEXT_PUBLIC_PRIVACY_MANAGER_ID,
      PSIPropertyId: NEXT_PUBLIC_PSI_APP_ID || NEXT_PUBLIC_TCF_PROPERTY_ID,
      domain: translation.domain
    };
    if (NEXT_PUBLIC_TCF_PROPERTY_HREF) {
      tcfConfig.propertyHref = `https://web-pre.${
        NEXT_PUBLIC_NEWSROOM === "omni" ? "omni" : "omniekonomi"
      }.se`;
    }

    initTCF(
      tcfConfig,
      userState.isLoggedIn ? userState?.userInfo.id : undefined
    )
      .then(async tcfClient => {
        const consents = await waitForConsent(tcfClient);

        initPulse(
          {
            clientId: NEXT_PUBLIC_PULSE_CLIENT_ID,
            newsroom: NEXT_PUBLIC_NEWSROOM,
            deployStage: NEXT_PUBLIC_PULSE_DEPLOY_STAGE,
            productTag: NEXT_PUBLIC_PULSE_PRODUCT_TAG
          },
          consents,
          userState.isLoggedIn
            ? userState.subscriptionsInfo.isPremium
            : undefined,
          userState.isLoggedIn ? userState.userInfo.id : undefined
        );
      })
      .catch(e => console.error(`Error when initilizing TCF and Pulse ${e}`));
  }, [
    userState.isLoggedIn,
    userState?.userInfo.id,
    userState?.subscriptionsInfo.isPremium
  ]);

  useEffect(() => {
    setUser({
      loggedIn: userState.isLoggedIn
    });

    setTag("user.loggedIn", userState.isLoggedIn);
    setTag("user.premium", !!userState.subscriptionsInfo?.isPremium);
    setTag("user.adFree", !!userState.subscriptionsInfo?.isAdFree);

    setContext("Subscriptions Info", userState.subscriptionsInfo);
    setContext("Usermix Data", userState.personalizedContent.usermix);
    setContext("Bookmark Data", {
      subsciptions: Array.isArray(userState.personalizedContent?.bookmarks)
        ? userState.personalizedContent.bookmarks.length
        : 0
    });
  }, [
    userState.isLoggedIn,
    userState.userInfo.id,
    userState.userInfo.email,
    userState.userInfo.name,
    userState.subscriptionsInfo,
    userState.personalizedContent.usermix,
    userState.personalizedContent.bookmarks
  ]);

  useEffect(() => {
    router?.events.on("routeChangeComplete", onNavigate);
    return () => router?.events.off("routeChangeComplete", onNavigate);
  }, [onNavigate, router?.events]);

  return { siteStateReady };
};

export { useSiteState };
