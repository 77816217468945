import type { UserData } from "../../../../server/getData";
import type { UserSlice } from "../types";

import { getExchangeToken } from "../../../../client";

const handleToken = (
  setExchangeToken: UserSlice["setExchangeToken"],
  serverUserData: UserData
): Promise<string> => {
  if (!serverUserData?.exchangeToken) {
    return getExchangeToken()
      .then(({ exchange_token }) => {
        const token = exchange_token;

        setExchangeToken(exchange_token);

        return token;
      })
      .catch(error => {
        console.error("Error when refreshing token: ", error);
        return "";
      });
  } else {
    const token = serverUserData.exchangeToken;

    setExchangeToken(serverUserData.exchangeToken);

    return Promise.resolve(token);
  }
};

export { handleToken };
