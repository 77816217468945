import * as Sentry from "@sentry/browser";

import type { UserSlice } from "../types";

import { verifyPremium } from "../../../../client";
import { PremiumStateError } from "../../../../client/PremiumStateError";

const handlePremiumState = async (
  setSubscriptionsInfo: UserSlice["setSubscriptionsInfo"],
  token: string
) => {
  try {
    const premiumInfo = await verifyPremium({ exchangeToken: token });

    setSubscriptionsInfo({
      isPremium: premiumInfo.isPremium,
      isAdFree: premiumInfo.isAdFree
    });

    return true;
  } catch (error) {
    console.error("Error when refreshing token: ", error);
    Sentry.captureException(error);

    if (!(error instanceof PremiumStateError)) {
      setSubscriptionsInfo({
        isPremium: true,
        isAdFree: true
      });
    }

    return false;
  }
};

export { handlePremiumState };
