import type { SiteSlice } from "../types";

import { getConfig } from "../../../../config";

const { features } = getConfig();

export const setCollapsedId = async (
  setCollapsedAdId: SiteSlice["setCollapsedAdId"]
) => {
  if (features.some(feature => feature === "ADS_ENABLED")) {
    window.addEventListener("message", event => {
      if (typeof event.data === "string" && event.data.includes("targetId")) {
        const { name, targetId } = JSON.parse(event.data);

        if (name === "hideAd") {
          setCollapsedAdId(targetId);
        }
      }
    });
  }
};
