import { useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";

import type { Identity } from "@schibsted/account-sdk-browser";
import type { UserData } from "../../../server/getData";

import {
  handleIsLoggedIn,
  handlePersonalizedContent,
  handlePremiumState,
  handleToken,
  handleUserInfo
} from "./handlers";
import { useGlobalStore } from "../store";
import { getIdentityInstance } from "./utils/getIdentityInstance";
import { getIsLoggedIn } from "./actions/getIsLoggedIn";
import { mergeUserData } from "./utils/mergeServerData";
import { getUserIdAndSig } from "./actions/getSigAndUserId";
import { handleUserPermissions } from "./handlers/handleUserPermissions";

const useUserState = (serverUserData: UserData) => {
  const [userStateReady, setUserStateReady] = useState(false);

  const {
    setIsLoggedIn,
    setFullUserState,
    setUserInfo,
    setExchangeToken,
    setSubscriptionsInfo,
    setPersonalizedContent,
    setUserPermissions,
    setDataFetchingResults
  } = useGlobalStore();

  useEffect(() => {
    const fetchUserData = async (identityInstance: Identity) => {
      try {
        window.SPiD_Identity = identityInstance;
        const isLoggedIn = await getIsLoggedIn(identityInstance);
        handleIsLoggedIn(setFullUserState, setIsLoggedIn, isLoggedIn);

        if (isLoggedIn) {
          const token = await handleToken(setExchangeToken, serverUserData);
          const { sig, userId } = await getUserIdAndSig(identityInstance);
          const userPermissionsPromise = handleUserPermissions(
            setUserPermissions,
            sig,
            userId
          );
          const userInfoPromise = handleUserInfo(setUserInfo);
          const premiumStatePromise = handlePremiumState(
            setSubscriptionsInfo,
            token
          );
          const personalizedContentPromise = handlePersonalizedContent(
            setPersonalizedContent
          );

          const results = await Promise.all([
            userInfoPromise,
            premiumStatePromise,
            personalizedContentPromise,
            userPermissionsPromise
          ]);

          setDataFetchingResults({
            info: results[0],
            premium: results[1],
            personalizedContent: results[2]
          });
        } else {
          await handleUserPermissions(setUserPermissions);
        }
      } catch (error) {
        console.error("Error while updating userState", error);
        Sentry.captureException(error);
      }

      setUserStateReady(true);
    };

    if (serverUserData) {
      setFullUserState(mergeUserData(serverUserData));
      const identityInstance = getIdentityInstance();

      if (identityInstance) {
        fetchUserData(identityInstance);
      }
    }
  }, [
    serverUserData,
    setExchangeToken,
    setFullUserState,
    setIsLoggedIn,
    setPersonalizedContent,
    setSubscriptionsInfo,
    setUserInfo,
    setUserPermissions,
    setDataFetchingResults
  ]);

  return { userStateReady };
};

export { useUserState };
