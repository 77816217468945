import { tcf } from "@schibsted/sourcepoint";
import Tracker from "@spt-tracking/pulse-sdk";

import type { Consents } from "@spt-tracking/pulse-sdk/dist/cjs/builders";
import type { CookieConsentConfig, TCFConfig } from "@schibsted/sourcepoint";
import type { TCF } from "../components/Ad/utils/cookieConsent";

interface PulseConfig {
  clientId: string;
  deployStage: string;
  newsroom: string;
  productTag?: string;
}

let trackerInstance: undefined | Tracker;

const isConsentSet = (consents: Consents) =>
  Object.values(consents.purposes).every(entry => entry.status !== "unknown");

export function waitForConsent(tcfClient: TCF): Promise<Consents> {
  return new Promise(resolve => {
    const consents = tcfClient.getCachedOrDefaultConsentsForPulse();
    if (!isConsentSet(consents)) {
      setTimeout(() => {
        resolve(waitForConsent(tcfClient));
      }, 2000);
    } else {
      resolve(consents);
    }
  });
}

export async function initPulse(
  pulseConfig: PulseConfig,
  consents: Consents,
  isPremium?: boolean,
  userId?: string
) {
  if (!trackerInstance) {
    trackerInstance = new Tracker(pulseConfig.clientId, {
      consents,
      requireAdvertisingOptIn: true
    });
  }

  const tracker = trackerInstance;

  window._pulseSdk = tracker;
  window.pulse = (callback: (tracker: Tracker) => void) => callback(tracker);

  tracker.update({
    deployStage: pulseConfig.deployStage,
    deployTag: pulseConfig.newsroom
  });
  tracker.update(
    {
      provider: {
        productTag: pulseConfig.productTag || pulseConfig.clientId
      }
    },
    true
  );

  tracker.update({
    actor: userId
      ? {
          id: userId,
          subscriptionName: isPremium ? "Premium" : "No SubscribedTopic",
          realm: "schibsted.com"
        }
      : undefined
  });

  return tracker;
}

export async function initTCF(
  tcfConfig: TCFConfig,
  userId?: string
): Promise<TCF> {
  if (
    typeof window === "undefined" ||
    typeof document === "undefined" ||
    typeof navigator === "undefined"
  ) {
    throw new Error("can't init TCF client in server environment");
  }

  const config: CookieConsentConfig = {
    baseEndpoint: `https://cmp.${tcfConfig.domain}`,
    propertyId: Number(tcfConfig.PSIPropertyId),
    consentLanguage: "sv",
    groupPmId: Number(tcfConfig.privacyManagerId),
    realm: "schibsted.com",
    clientId: tcfConfig.clientId,
    identityObjectName: "SPiD_Identity",
    showInWebview: "tcf"
  };

  if (tcfConfig?.propertyHref) {
    config.propertyHref = tcfConfig.propertyHref;
  }

  if (typeof userId !== "undefined") {
    const uId = parseInt(userId);
    if (!isNaN(uId)) {
      config.userId = uId;
    }
  }

  tcf(window, document, navigator, config);

  return window._tcf_;
}
