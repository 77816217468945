import * as Sentry from "@sentry/browser";

import type { UserSlice } from "../types";

import { getSettings } from "../../../../client";

const handlePersonalizedContent = async (
  setPersonalizedContent: UserSlice["setPersonalizedContent"]
) => {
  try {
    const settings = await getSettings();

    setPersonalizedContent({
      usermix: settings?.settings?.usermix,
      bookmarks: settings?.bookmarks,
      subscribedTopics: settings?.subscriptions
    });

    return true;
  } catch (error) {
    console.error("Error when fetching user settings: ", error);
    Sentry.captureException(error);
    return false;
  }
};

export { handlePersonalizedContent };
