import type {
  HasSessionSuccessResponse,
  HasSessionFailureResponse,
  Identity
} from "@schibsted/account-sdk-browser";

const isError = (
  response: HasSessionSuccessResponse | HasSessionFailureResponse
): response is HasSessionFailureResponse =>
  (response as HasSessionFailureResponse).error !== undefined;

const getUserIdAndSig = async (
  identity: Identity
): Promise<{ sig: string; userId: string }> => {
  try {
    const session = await identity.hasSession();

    if (isError(session)) {
      throw new Error(
        `Error of type ${session.error.type} with description ${session.error.description} when getting sig`
      );
    }

    return { sig: session.sig, userId: session.userId.toString() };
  } catch (e) {
    console.error(e);

    return { sig: "", userId: "" };
  }
};

export { getUserIdAndSig };
