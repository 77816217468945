import type { UserSlice } from "../types";

import {
  getAnonymousPermissions,
  getUserPermissions
} from "../../../../server/lbmpService";

const handleUserPermissions = (
  setUserPermissions: UserSlice["setUserPermissions"],
  sig?: string,
  userId?: string
) => {
  if (sig && userId) {
    return getUserPermissions(`sdrn:schibsted.com:user:${userId}`, sig)
      .then(permissionResponse => {
        const permissions = permissionResponse.permissions;
        if (permissions) {
          setUserPermissions(permissions);
        }
      })
      .catch(e => {
        console.error(e);
      });
  } else {
    const sdk = window._pulseSdk;
    return sdk
      ?.getEnvironmentId()
      .then(pulseEnvId => {
        return getAnonymousPermissions(
          `sdrn:schibsted:environment:${pulseEnvId}`
        ).then(permissionResponse => {
          const permissions = permissionResponse.permissions;
          if (permissions) {
            setUserPermissions(permissions);
          }
        });
      })
      .catch(e => {
        console.error(e);
      });
  }
};

export { handleUserPermissions };
