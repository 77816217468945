import type { UserData } from "../../../../server/getData";

import { initialUserState } from "../initialState";

const mergeUserData = (serverUserData: UserData) => {
  return {
    ...initialUserState,
    exchangeToken: serverUserData.exchangeToken
      ? serverUserData.exchangeToken
      : initialUserState.exchangeToken,
    subscriptionsInfo: {
      isAdFree: serverUserData.isAdFree,
      isPremium: serverUserData.isPremium
    },
    personalizedContent: {
      ...initialUserState.personalizedContent,
      usermix: serverUserData.usermix
        ? serverUserData.usermix
        : initialUserState.personalizedContent.usermix
    }
  };
};

export { mergeUserData };
