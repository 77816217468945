import type { UserSlice } from "../types";

import { initialUserState } from "../initialState";

const handleIsLoggedIn = (
  setFullUserState: UserSlice["setFullUserState"],
  setIsLoggedIn: UserSlice["setIsLoggedIn"],
  isLoggedIn: boolean
) => {
  if (!isLoggedIn) {
    setFullUserState(initialUserState);

    return;
  }

  setIsLoggedIn(isLoggedIn);
};

export { handleIsLoggedIn };
