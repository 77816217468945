import { getConfig } from "../config";

type Permission = { purpose: string; data: string[] };

type PermissionResponse = {
  permissions: Permissions;
};

export type Permissions = Permission[];

const { NEXT_PUBLIC_LBMP_API_BASE, NEXT_PUBLIC_LBMP_PUBLISHER } = getConfig();

const validateSdrn = (sdrn: string): string => {
  const pulseSdrnRegex =
    /^sdrn:[a-zA-Z0-9_.-]*:[a-zA-Z0-9_.-]*:[a-zA-Z0-9_.-]*$/;
  if (!pulseSdrnRegex.test(sdrn)) {
    throw new Error(`Invalid pulse sdrn: ${sdrn}`);
  }
  return sdrn;
};

export const getAnonymousPermissions = async (
  sdrn: string
): Promise<PermissionResponse> => {
  const validatedSdrn = validateSdrn(sdrn);
  const url = `${NEXT_PUBLIC_LBMP_API_BASE}/anonymous/${NEXT_PUBLIC_LBMP_PUBLISHER}/user/${validatedSdrn}/permissions`;

  const response = await fetch(url, {
    headers: { "Content-Type": "application/json;charset=UTF-8" }
  });

  return await response.json();
};

export const getUserPermissions = async (
  sdrn: string,
  sig: string
): Promise<PermissionResponse> => {
  const validatedSdrn = validateSdrn(sdrn);
  const url = `${NEXT_PUBLIC_LBMP_API_BASE}/spid/${NEXT_PUBLIC_LBMP_PUBLISHER}/user/${validatedSdrn}/permissions`;

  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `SPIDSIG ${sig}`
    }
  });

  return response.json();
};
