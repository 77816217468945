import React from "react";

import type { ReactNode } from "react";
import type { SiteData, UserData } from "../../server/getData";

import { useUserState } from "./userState/useUserState";
import { useSiteState } from "./siteState/useSiteState";

export interface GlobalStateProps {
  children: ReactNode;
  siteData: SiteData;
  userData: UserData;
}

export const GlobalState = ({
  children,
  siteData,
  userData
}: GlobalStateProps) => {
  const { userStateReady } = useUserState(userData);
  const { siteStateReady } = useSiteState(siteData);

  return <>{userStateReady && siteStateReady ? children : null}</>;
};
